let OSS = require('ali-oss');
// 正式
let ossConfig = {
  region: 'oss-cn-hangzhou',
  accessKeyId: 'LTAI5tDoULgPjous7bNBpf1N',
  accessKeySecret: 'CpX10PMhm8D2fYulVEOs4TEUBZ4RjI',
  bucket: 'guolao-pro',
  cname: true,
  endpoint: 'https://guolao-pro.oss-cn-hangzhou.aliyuncs.com'
}
// 测试
// let ossConfig = {
//   region: 'oss-cn-hangzhou',
//   accessKeyId: 'LTAI5tDoULgPjous7bNBpf1N',
//   accessKeySecret: 'CpX10PMhm8D2fYulVEOs4TEUBZ4RjI',
//   bucket: 'guolao-dev',
//   cname: true,
//   endpoint: 'https://guolao-dev.oss-cn-hangzhou.aliyuncs.com'
// }

let client = new OSS(ossConfig);

export default client
